<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import {
  categorySaleList,
  categorySaleListSummary
} from '@/api/decision/saleRankReport/goodsCategory' //类别销售排行
export default {
  name: 'saleRankReportCategory',
  components: {
    TablePage
  },
  data () {
    return {
      options: {
        summary: '',
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        title: '类别销售排行',
        rowKey: 'shopId',
        search: [
          {
            label: '销售时间',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '门店编号/门店名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'categoryNos', label: '类别编码' },
              { filter: 'categoryNames', label: '类别名称' }
            ]
          },
          this.$select({
            key: 'listCategory',
            option: {
              filter: 'categoryIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品类别',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'category' })
                    }
                  }
                ]
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabColumnType: 'salesVolume',
        tabsColumns: [
          {
            title: '销量排行',
            type: 'salesVolume',
            getSummaryApi: categorySaleListSummary,
            getListApi: categorySaleList,
            defaultBody: { rankingType: 1 },
            exportOption: {
              ossKeyUrl:
                '/api/system/shop/strategy/categorySale/ranking/getExportGoodsInfo',
              fastExportUrl:
                '/api/system/shop/strategy/categorySale/ranking/export',
              exportName: '类别销量排行'
            },
            columns: [
              {
                prop: 'categoryNo',
                label: '类别编码',
                minWidth: 150
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'lineGain',
                label: '毛利',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoneySum',
                label: '总优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'lineGain', 'agioMoneySum']
          },
          {
            title: '销售额排行',
            type: 'salesMoney',
            getSummaryApi: categorySaleListSummary,
            getListApi: categorySaleList,
            defaultBody: { rankingType: 2 },
            exportOption: {
              ossKeyUrl:
                '/api/system/shop/strategy/categorySale/ranking/getExportGoodsInfo',
              fastExportUrl:
                '/api/system/shop/strategy/categorySale/ranking/export',
              exportName: '类别销售额排行'
            },
            columns: [
              {
                prop: 'categoryNo',
                label: '类别编码',
                minWidth: 150
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'lineGain',
                label: '毛利',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoneySum',
                label: '总优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'lineGain', 'agioMoneySum']
          },
          {
            title: '销售毛利排行',
            type: 'salesGrossProfit',
            getSummaryApi: categorySaleListSummary,
            getListApi: categorySaleList,
            defaultBody: { rankingType: 3 },
            exportOption: {
              ossKeyUrl:
                '/api/system/shop/strategy/categorySale/ranking/getExportGoodsInfo',
              fastExportUrl:
                '/api/system/shop/strategy/categorySale/ranking/export',
              exportName: '类别销售毛利排行'
            },
            columns: [
              {
                prop: 'categoryNo',
                label: '类别编码',
                minWidth: 150
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'lineGain',
                label: '毛利',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoneySum',
                label: '总优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'lineGain', 'agioMoneySum']
          }
        ]
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'update':
          break
        default:
          break
      }
    }
  }
}
</script>
