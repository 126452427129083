<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  vipFillPayAnalysisList,
  vipFillPayAnalysisListSummary,
} from "@/api/decision/vipReport/vipFillPayAnalysis"; // 会员充值消费走势分析
import moment from "moment";
export default {
  name: "vipFillPayAnalysis",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        loading: false,
        pagination: {
          onlySelectTotal: true,
          total: 0,
        },
        title: "会员充值消费走势分析",
        listNo: true, // 序号
        //搜索
        search: [
          {
            hide: () => this.options?.tabColumnType == "date",
            label: "日期",
            type: "daterange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
          },
          {
            hide: () => this.options?.tabColumnType == "date",
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "week",
            data: [
              "day",
              "yesterday",
              "week",
              "lastWeek",
              "month",
              "lastMonth",
            ],
          },
          {
            hide: () => this.options?.tabColumnType == "dateMonth",
            label: "日期",
            type: "monthrange",
            // filter: 'billDate',
            model: [moment().subtract(3, 'months').startOf('month').format('yyyy-MM'), moment().startOf('month').format('yyyy-MM')],
            defaultValue: [moment().subtract(3, 'months').startOf('month').format('yyyy-MM'), moment().startOf('month').format('yyyy-MM')],
            noReset: true,
            filterFnc: ([a, b] = []) => {
              console.log("a", a);
              console.log("b", b);
              return {
                beginBillDate: a + "-01" + " 00:00:00",
                endBillDate: b + "-01" + " 00:00:00",
              };
            },
          },
          {
            type: "filters",
            tip: "门店编号/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编号" },
              { filter: "shopNames", label: "门店名称" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "date",
        tabsColumns: [
          {
            title: "按日期查询",
            type: "date",
            getListApi: vipFillPayAnalysisList,
            getSummaryApi: vipFillPayAnalysisListSummary,
            getDataKey: { list: "data" },
            defaultBody: { summaryType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/strategy/vipRechargeConsumeTrend/listExport",
              exportName: "按日期查询",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "payMoney",
                label: "付款金额",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "fillMoney",
                label: "充值金额",
                minWidth: 120,
                align: "center",
              },
            ],
            summary: ["payMoney", "fillMoney"],
            topEchartsHeight: 500,
            getTopEchartsOption: async () => {
              await this.handleEvent("setIntervalList");
              await this.$nextTick();
              return {
                tooltip: {
                  trigger: "axis", // 提示框触发类型
                  axisPointer: {
                    crossStyle: {
                      color: "#73d13d", // 十字准星的颜色
                    },
                  },
                  formatter: (params) => {
                    return `${params[0].name}<br>${params[0].marker}付款金额:${params[0].data}(元)<br>${params[1].marker}充值金额:${params[1].data}(元)`; // 提示框内容格式化
                  },
                },
                legend: {
                  data: ["付款金额", "充值金额"], // 图例名称
                  itemWidth: 30, // 设置图例项的宽度，增加线的长度
                  // itemHeight: 0, // 设置图例项的高度为0，隐藏中间的点
                  textStyle: {
                    fontSize: 14, //文本大小
                    color: "#333", // 图例文本颜色
                  },
                },
                grid: {
                  left: "1%", // 图表左边空白
                  right: "1%", // 图表右边空白
                  bottom: "5%", // 图表底部空白
                  containLabel: true, // 包含坐标轴的刻度标签
                },
                xAxis: [
                  {
                    type: "category", // x 轴类型为类目轴
                    data: this.initChartChart.dateStrArr, // x 轴数据
                    axisTick: {
                      show: false, // 不显示刻度线
                    },
                    axisLine: {
                      lineStyle: {
                        color: "#dddddd", // x 轴线颜色
                      },
                    },
                    // boundaryGap: false, // 两端空白策略，false 表示留白
                    axisLabel: {
                      color: "#cccccc", // x 轴文本的颜色
                      fontSize: 14, // x 轴标签字体大小
                    },
                  },
                ],
                yAxis: [
                  {
                    type: "value", // y 轴类型为数值轴
                    name: "", // y 轴名称
                    nameGap: 25, // 设置标题与y轴的距离
                    axisLine: {
                      show: false, // 不显示坐标轴轴线
                      lineStyle: {
                        color: "#999999", // y 轴线颜色
                      },
                    },
                    axisTick: {
                      show: false, // 不显示刻度线
                    },
                    splitLine: {
                      lineStyle: {
                        type: "dashed", // 分隔线样式为虚线
                        color: "#E8E8E8", // y 轴线颜色
                      },
                    },
                    axisLabel: {
                      formatter: "{value} 元", // 自定义刻度线显示格式，显示数值和单位
                      fontSize: 14, // y 轴标签字体大小
                    },
                  },
                ],
                series: [
                  {
                    name: "付款金额", // 系列名称
                    type: "line", // 系列类型为折线图
                    data: this.initChartChart.payMoneyList, // 系列数据
                    yAxisIndex: 0, // 使用的 y 轴索引
                    symbol: "circle", // 数据点图形
                    symbolSize: 8, // 数据点大小
                    itemStyle: {
                      color: "#40B5FF", // 数据项样式
                    },
                    lineStyle: {
                      color: "#40B5FF", // 折线样式
                    },
                  },
                  {
                    name: "充值金额", // 系列名称
                    type: "line", // 系列类型为折线图
                    data: this.initChartChart.fillMoneyList, // 系列数据
                    yAxisIndex: 0, // 使用的 y 轴索引
                    symbol: "circle", // 数据点图形
                    symbolSize: 8, // 数据点大小
                    itemStyle: {
                      color: "#FA8219", // 数据项样式
                    },
                    lineStyle: {
                      color: "#FA8219", // 折线样式
                    },
                  },
                ],
              };
            },
          },
          {
            title: "按月份查询",
            type: "dateMonth",
            getListApi: vipFillPayAnalysisList,
            getSummaryApi: vipFillPayAnalysisListSummary,
            getDataKey: { list: "data" },
            defaultBody: { summaryType: 2 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/strategy/vipRechargeConsumeTrend/listExport",
              exportName: "按月份查询",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "月份",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "payMoney",
                label: "付款金额",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "fillMoney",
                label: "充值金额",
                minWidth: 120,
                align: "center",
              },
            ],
            summary: ["payMoney", "fillMoney"],
            getTopEchartsOption: async () => {
              await this.handleEvent("setIntervalList");
              await this.$nextTick();
              return {
                tooltip: {
                  trigger: "axis", // 提示框触发类型
                  axisPointer: {
                    crossStyle: {
                      color: "#73d13d", // 十字准星的颜色
                    },
                  },
                  formatter: (params) => {
                    return `${params[0].name}<br>${params[0].marker}付款金额:${params[0].data}(元)<br>${params[1].marker}充值金额:${params[1].data}(元)`; // 提示框内容格式化
                  },
                },
                legend: {
                  data: ["付款金额", "充值金额"], // 图例名称
                  itemWidth: 30, // 设置图例项的宽度，增加线的长度
                  // itemHeight: 0, // 设置图例项的高度为0，隐藏中间的点
                  textStyle: {
                    fontSize: 14, //文本大小
                    color: "#333", // 图例文本颜色
                  },
                },
                grid: {
                  left: "1%", // 图表左边空白
                  right: "1%", // 图表右边空白
                  bottom: "5%", // 图表底部空白
                  containLabel: true, // 包含坐标轴的刻度标签
                },
                xAxis: [
                  {
                    type: "category", // x 轴类型为类目轴
                    data: this.initChartChart.dateStrArr, // x 轴数据
                    axisTick: {
                      show: false, // 不显示刻度线
                    },
                    axisLine: {
                      lineStyle: {
                        color: "#dddddd", // x 轴线颜色
                      },
                    },
                    // boundaryGap: false, // 两端空白策略，false 表示留白
                    axisLabel: {
                      color: "#cccccc", // x 轴文本的颜色
                      fontSize: 14, // x 轴标签字体大小
                    },
                  },
                ],
                yAxis: [
                  {
                    type: "value", // y 轴类型为数值轴
                    name: "", // y 轴名称
                    nameGap: 25, // 设置标题与y轴的距离
                    axisLine: {
                      show: false, // 不显示坐标轴轴线
                      lineStyle: {
                        color: "#999999", // y 轴线颜色
                      },
                    },
                    axisTick: {
                      show: false, // 不显示刻度线
                    },
                    splitLine: {
                      lineStyle: {
                        type: "dashed", // 分隔线样式为虚线
                        color: "#E8E8E8", // y 轴线颜色
                      },
                    },
                    axisLabel: {
                      formatter: "{value} 元", // 自定义刻度线显示格式，显示数值和单位
                      fontSize: 14, // y 轴标签字体大小
                    },
                  },
                ],
                series: [
                  {
                    name: "付款金额", // 系列名称
                    type: "line", // 系列类型为折线图
                    data: this.initChartChart.payMoneyList, // 系列数据
                    yAxisIndex: 0, // 使用的 y 轴索引
                    symbol: "circle", // 数据点图形
                    symbolSize: 8, // 数据点大小
                    itemStyle: {
                      color: "#40B5FF", // 数据项样式
                    },
                    lineStyle: {
                      color: "#40B5FF", // 折线样式
                    },
                  },
                  {
                    name: "充值金额", // 系列名称
                    type: "line", // 系列类型为折线图
                    data: this.initChartChart.fillMoneyList, // 系列数据
                    yAxisIndex: 0, // 使用的 y 轴索引
                    symbol: "circle", // 数据点图形
                    symbolSize: 8, // 数据点大小
                    itemStyle: {
                      color: "#FA8219", // 数据项样式
                    },
                    lineStyle: {
                      color: "#FA8219", // 折线样式
                    },
                  },
                ],
              };
            },
          },
        ],
      },
      initChartChart: {}, //图表
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "setIntervalList":
          this.initChartChart = {
            dateStrArr: [],
            payMoneyList: [],
            fillMoneyList: [],
          };
          this.options.list.forEach((range, i) => {
            this.initChartChart.dateStrArr.push(range.billDateStr);
            this.initChartChart.payMoneyList.push(range.payMoney);
            this.initChartChart.fillMoneyList.push(range.fillMoney);
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
