<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  vipShopAnalysisList,
  vipShopAnalysisListSummary,
} from "@/api/decision/vipReport/vipShopAnalysis"; //会员门店经营分析
export default {
  name: "vipShopAnalysis",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "会员门店经营分析",
        listNo: true, // 序号
        //搜索
        search: [
          {
            label: "消费时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: [
              "day",
              "yesterday",
              "week",
              "lastWeek",
              "month",
              "lastMonth",
            ],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编号" },
              { filter: "shopNames", label: "门店名称" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "shopSummary",
        tabsColumns: [
          {
            title: "按门店汇总",
            type: "shopSummary",
            getListApi: vipShopAnalysisList,
            getSummaryApi: vipShopAnalysisListSummary,
            defaultBody: { summaryType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/strategy/vipShopOperate/listExport",
              exportName: "按门店汇总",
            },
            //表格
            columns: [
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "fillMoney",
                label: "充值金额",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "largessMoney",
                label: "赠送金额",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "consumeMoney",
                label: "消费金额",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "score",
                label: "积分",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "newVip",
                label: "新增会员数",
                minWidth: 120,
                align: "center",
              },
            ],
            summary: [
              "fillMoney",
              "largessMoney",
              "consumeMoney",
              "score",
              "newVip",
            ],
          },
          {
            title: "按门店汇总+日期",
            type: "shopDateSummary",
            getListApi: vipShopAnalysisList,
            getSummaryApi: vipShopAnalysisListSummary,
            defaultBody: { summaryType: 2 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/strategy/vipShopOperate/listExport",
              exportName: "按门店汇总+日期",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "fillMoney",
                label: "充值金额",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "largessMoney",
                label: "赠送金额",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "consumeMoney",
                label: "消费金额",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "score",
                label: "积分",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "newVip",
                label: "新增会员数",
                minWidth: 120,
                align: "center",
              },
            ],
            summary: [
              "fillMoney",
              "largessMoney",
              "consumeMoney",
              "score",
              "newVip",
            ],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      console.log("sssss", this.options);
      switch (type) {
        default:
          break;
      }
    },
  },
};
</script>
