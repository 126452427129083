<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import moment from "moment";
import {
  sellOutList,
  sellOutListSummary,
} from "@/api/decision/businessReport/sellOut"; //断货时间分析
export default {
  name: "sellOut",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        isRestTablecolumns: true,
        body: {},
        title: "断货时间分析",
        rowKey: "shopId",
        search: [
          {
            label: "销售月份",
            type: "month",
            filter: "month",
            disabledFuture: true,
            model: moment().format("YYYY-MM"),
            defaultValue: moment().format("YYYY-MM"),
            filterFnc: (a) => ({ billDate: a + "-01" + " 00:00:00" }),
          },
          {
            type: "filters",
            tip: "门店编码/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编码" },
              { filter: "shopNames", label: "门店名称" },
              { filter: "goodsNos", label: "商品编码" },
              { filter: "goodsNames", label: "商品名称" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            type: "textTip",
            tip: "数据统计截止到昨天",
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        defaultBody: { searchType: "dateShop" },
        // getSummaryApi: sellOutListSummary,
        getListApi: sellOutList,
        type: "dateShop",
        exportOption: {
          fastExportUrl: "/api/system/shop/strategy/break/listExport",
          exportName: "断货时间分析",
        },
        defaultBody: { summaryType: 1 },
        columns: [
          {
            prop: "goodsNo",
            label: "商品编码",
            minWidth: 150,
          },
          {
            prop: "goodsName",
            label: "商品名称",
            minWidth: 150,
          },
          {
            prop: "date",
            label: "日期",
            minWidth: 150,
            getColumns: () =>
              this.options.list?.[0]?.returnRateDates?.map?.((x) => ({
                ...x,
                label: x.day + "号",
                prop: `returnRateDates.${x.day}`,
                minWidth: 110,
                // summary: true,
                formatter: (v, row) => {
                  console.log("sss", row);
                  return row.returnRateDates?.find((y) => y.day === x.day)
                    ?.breakTime;
                },
              })) || [],
          },
          {
            prop: "break0006Day",
            label: "00:00-6:00断货天数",
            minWidth: 170,
            sortable: true,
          },
          {
            prop: "break0815Day",
            label: "08:00-15:00断货天数",
            minWidth: 170,
            sortable: true,
          },
          {
            prop: "break1520Day",
            label: "15:00-20:00断货天数",
            minWidth: 170,
            sortable: true,
          },
          {
            prop: "break2024Day",
            label: "20:00-24:00断货天数",
            minWidth: 170,
            sortable: true,
          },
        ],
        // summary: [
        //   "break0006Day",
        //   "break0815Day",
        //   "break1520Day",
        //   "break2024Day",
        // ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        default:
          break;
      }
    },
    calculateDaysInMonth() {
      console.log("sss", this.options);
      let inputDate = this?.options?.search[0]?.model;
      console.log("sss1", this.options);
      if (inputDate) {
        const [year, month] = inputDate.split("-").map(Number);
        const daysInMonth = new Date(year, month, 0).getDate();
        return daysInMonth;
      }
    },
  },
};
</script>
