<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import moment from "moment";
import TablePage from "@/components/tablePage";
import {
  vipDayAnalysisList,
  vipDayAnalysisListSummary,
} from "@/api/decision/vipReport/vipDayAnalysis"; // 商品每日每月情况分析
export default {
  name: "vipDayAnalysis",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "商品每日每月情况分析",
        rowKey: "goodsId",
        search: [
          {
            hide: () => this.options?.tabColumnType == "date",
            label: "日期",
            type: "daterange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
          },
          {
            hide: () => this.options?.tabColumnType == "date",
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "yesterday",
            data: [
              "yesterday",
              "week",
              "lastWeek",
              "month",
              "lastMonth",
            ],
          },
          {
            hide: () => this.options?.tabColumnType == "dateMonth",
            label: "单据日期",
            type: "monthrange",
            // filter: 'billDate',
            model: [moment().format("yyyy-MM"), moment().format("yyyy-MM")],
            defaultValue: [
              moment().format("yyyy-MM"),
              moment().format("yyyy-MM"),
            ],
            noReset: true,
            filterFnc: ([a, b] = []) => {
              console.log("a", a);
              console.log("b", b);
              return {
                beginBillDate: a + "-01" + " 00:00:00",
                endBillDate: b + "-01" + " 00:00:00",
              };
            },
          },
          {
            type: "textTip",
            tip: "数据统计截止到昨天",
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "date",
        tabsColumns: [
          {
            title: "每日情况分析",
            type: "date",
            getSummaryApi: vipDayAnalysisListSummary,
            getListApi: vipDayAnalysisList,
            defaultBody: { summaryType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/strategy/vipSituation/listExport",
              exportName: "每日情况分析",
            },
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 155,
              },
              {
                prop: "fillMoney",
                label: "充值金额",
                minWidth: 150,
              },
              {
                prop: "fillCount",
                label: "充值次数",
                minWidth: 150,
              },
              {
                prop: "largessMoney",
                label: "赠送金额",
                minWidth: 150,
              },
              {
                prop: "score",
                label: "积分",
                minWidth: 150,
              },
              {
                prop: "scoreCount",
                label: "积分次数",
                minWidth: 150,
              },
              {
                prop: "payScore",
                label: "使用/兑换积分",
                minWidth: 120,
              },
              {
                prop: "payScoreMoney",
                label: "积分抵现金额",
                minWidth: 150,
              },
              {
                prop: "consumeMoney",
                label: "消费金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "consumeCount",
                label: "消费次数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "avgFillMoney",
                label: "充值客单价",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "avgConsumeMoney",
                label: "消费客单价",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "付款金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payCount",
                label: "付款次数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "usableFillMoney",
                label: "可用充值金额",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "usableLargessMoney",
                label: "可用赠送金额",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "usableGainMoney",
                label: "可用返现金额",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "usableMoney",
                label: "卡内总余额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "usableScore",
                label: "可用积分",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: [
              "fillMoney",
              "fillCount",
              "largessMoney",
              "score",
              "scoreCount",
              "payScore",
              "payScoreMoney",
              "consumeMoney",
              "consumeCount",
              "payMoney",
              "payCount",
              "usableFillMoney",
              "usableLargessMoney",
              "usableGainMoney",
              "usableMoney",
              "usableScore",
            ],
          },
          {
            title: "每月情况分析",
            type: "dateMonth",
            getSummaryApi: vipDayAnalysisListSummary,
            getListApi: vipDayAnalysisList,
            defaultBody: { summaryType: 2 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/strategy/vipSituation/listExport",
              exportName: "每月情况分析",
            },
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 155,
              },
              {
                prop: "fillMoney",
                label: "充值金额",
                minWidth: 150,
              },
              {
                prop: "fillCount",
                label: "充值次数",
                minWidth: 150,
              },
              {
                prop: "largessMoney",
                label: "赠送金额",
                minWidth: 150,
              },
              {
                prop: "score",
                label: "积分",
                minWidth: 150,
              },
              {
                prop: "scoreCount",
                label: "积分次数",
                minWidth: 150,
              },
              {
                prop: "payScore",
                label: "使用/兑换积分",
                minWidth: 120,
              },
              {
                prop: "payScoreMoney",
                label: "积分抵现金额",
                minWidth: 130,
              },
              {
                prop: "consumeMoney",
                label: "消费金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "consumeCount",
                label: "消费次数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "avgFillMoney",
                label: "充值客单价",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "avgConsumeMoney",
                label: "消费客单价",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "付款金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payCount",
                label: "付款次数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "usableFillMoney",
                label: "可用充值金额",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "usableLargessMoney",
                label: "可用赠送金额",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "usableGainMoney",
                label: "可用返现金额",
                minWidth: 130,
                sortable: true,
              },
              {
                prop: "usableMoney",
                label: "卡内总余额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "usableScore",
                label: "可用积分",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: [
              "fillMoney",
              "fillCount",
              "largessMoney",
              "score",
              "scoreCount",
              "payScore",
              "payScoreMoney",
              "consumeMoney",
              "consumeCount",
              "payMoney",
              "payCount",
              "usableFillMoney",
              "usableLargessMoney",
              "usableGainMoney",
              "usableMoney",
              "usableScore",
            ],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          break;
        default:
          break;
      }
    },
  },
};
</script>
