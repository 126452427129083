<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import {
  saleMonthTotalList,
  saleMonthTotalListSummary
} from '@/api/decision/saleQueryReport/saleMonthTotal' // 销售月汇总
export default {
  name: 'saleMonthTotal',
  components: {
    TablePage
  },
  data () {
    return {
      options: {
        summary: '',
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        title: '销售月汇总',
        rowKey: 'shopId',
        search: [
          {
            label: '销售月份',
            type: 'monthrange',
            model: [
              new Date().getFullYear() +
                '-' +
                String(new Date().getMonth() + 1).padStart(2, '0'),
              new Date().getFullYear() +
                '-' +
                String(new Date().getMonth() + 1).padStart(2, '0')
            ],
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + '-01' + ' 00:00:00',
              endBillDate: b + '-01' + ' 00:00:00'
            }),
            defaultValue:[
              new Date().getFullYear() +
                '-' +
                String(new Date().getMonth() + 1).padStart(2, '0'),
              new Date().getFullYear() +
                '-' +
                String(new Date().getMonth() + 1).padStart(2, '0')
            ]
          },
          {
            type: 'filters',
            tip: '门店编码/门店名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'goodsNos', label: '商品编号' },
              { filter: 'goodsNames', label: '商品名称' },
              { filter: 'categoryNos', label: '类别编号' },
              { filter: 'categoryNames', label: '类别名称' },
              { filter: 'shopNos', label: '门店编码' },
              { filter: 'shopNames', label: '门店名称' }
            ]
          },
          this.$select({
            key: 'listGoods',
            option: {
              filter: 'goodsIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == 'single',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'goods' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listCategory',
            option: {
              filter: 'categoryIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == 'category',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品类别',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'category' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType != 'cashier' ||
                this.options?.tabColumnType == 'shop',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listCashier',
            option: {
              filter: 'cashierIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == 'cashier',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择收银员',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'cashier' })
                    }
                  }
                ]
              }
            }
          }),
          {
            type: 'textTip',
            tip: '数据统计截止到昨天'
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabColumnType: 'single',
        tabsColumns: [
          {
            title: '单品汇总查询',
            type: 'single',
            getListApi: saleMonthTotalList,
            getSummaryApi: saleMonthTotalListSummary,
            defaultBody: { summaryType: 1 },
            exportOption: {
              fastExportUrl:
                '/api/system/shop/strategy/saleMonth/ranking/listExport',
              exportName: '单品汇总查询'
            },
            columns: [
              {
                prop: 'billMonth',
                label: '月份',
                minWidth: 100
              },
              {
                prop: 'shopNo',
                label: '门店编码',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 150
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 180
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 150
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 120
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 120
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'agioMoneySum',
                label: '优惠金额',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'refundBasUnitQty',
                label: '退货数量',
                minWidth: 120
              },
              {
                prop: 'refundMoney',
                label: '退货金额',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'largessBasUnitQty',
                label: '赠送数量',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'largessMoney',
                label: '赠送金额',
                minWidth: 120,
                sortable: true
              }
            ],
            summary: [
              'unitQty',
              'unitMoney',
              'agioMoneySum',
              'refundBasUnitQty',
              'refundMoney',
              'largessBasUnitQty',
              'largessMoney'
            ]
          },
          {
            title: '分店分类汇总查询',
            type: 'category',
            getSummaryApi: saleMonthTotalListSummary,
            getListApi: saleMonthTotalList,
            defaultBody: { summaryType: 2 },
            exportOption: {
              fastExportUrl:
                '/api/system/shop/strategy/saleMonth/ranking/listExport',
              exportName: '分店分类汇总查询'
            },
            columns: [
              {
                prop: 'billMonth',
                label: '月份',
                minWidth: 100
              },
              {
                prop: 'shopNo',
                label: '门店编码',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 150
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoneySum',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'refundBasUnitQty',
                label: '退货数量',
                minWidth: 120
              },
              {
                prop: 'refundMoney',
                label: '退货金额',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'largessBasUnitQty',
                label: '赠送数量',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'largessMoney',
                label: '赠送金额',
                minWidth: 120,
                sortable: true
              }
            ],
            summary: [
              'unitQty',
              'unitMoney',
              'agioMoneySum',
              'refundBasUnitQty',
              'refundMoney',
              'largessBasUnitQty',
              'largessMoney'
            ]
          },
          {
            title: '时间段汇总查询',
            type: 'time',
            getSummaryApi: saleMonthTotalListSummary,
            getListApi: saleMonthTotalList,
            defaultBody: { summaryType: 3 },
            exportOption: {
              fastExportUrl:
                '/api/system/shop/strategy/saleMonth/ranking/listExport',
              exportName: '时间段汇总查询'
            },
            columns: [
              {
                prop: 'billMonth',
                label: '月份',
                minWidth: 100
              },
              {
                prop: 'shopNo',
                label: '门店编码',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'timeSlotStr',
                label: '时间段',
                minWidth: 100
              },
              {
                prop: 'customerUnitPrice',
                label: '客单价',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'customerUnitQty',
                label: '客单数',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: [
              'customerUnitPrice',
              'customerUnitQty',
              'unitQty',
              'unitMoney'
            ]
          },
          {
            title: '收银员汇总查询',
            type: 'cashier',
            getSummaryApi: saleMonthTotalListSummary,
            getListApi: saleMonthTotalList,
            defaultBody: { summaryType: 4 },
            exportOption: {
              fastExportUrl:
                '/api/system/shop/strategy/saleMonth/ranking/listExport',
              exportName: '收银员汇总查询'
            },
            columns: [
              {
                prop: 'billMonth',
                label: '月份',
                minWidth: 100
              },
              {
                prop: 'cashierNo',
                label: '收银员编号',
                minWidth: 155
              },
              {
                prop: 'cashierName',
                label: '收银员名称',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoneySum',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'refundBasUnitQty',
                label: '退货数量',
                minWidth: 120
              },
              {
                prop: 'refundMoney',
                label: '退货金额',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'largessBasUnitQty',
                label: '赠送数量',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'largessMoney',
                label: '赠送金额',
                minWidth: 120,
                sortable: true
              }
            ],
            summary: [
              'unitQty',
              'unitMoney',
              'agioMoneySum',
              'refundBasUnitQty',
              'refundMoney',
              'largessBasUnitQty',
              'largessMoney'
            ]
          },
          {
            title: '分店汇总查询',
            type: 'shop',
            getSummaryApi: saleMonthTotalListSummary,
            getListApi: saleMonthTotalList,
            defaultBody: { summaryType: 5 },
            exportOption: {
              fastExportUrl:
                '/api/system/shop/strategy/saleMonth/ranking/listExport',
              exportName: '分店汇总查询'
            },
            columns: [
              {
                prop: 'billMonth',
                label: '月份',
                minWidth: 100
              },
              {
                prop: 'shopNo',
                label: '门店编码',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoneySum',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'refundBasUnitQty',
                label: '退货数量',
                minWidth: 120
              },
              {
                prop: 'refundMoney',
                label: '退货金额',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'largessBasUnitQty',
                label: '赠送数量',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'largessMoney',
                label: '赠送金额',
                minWidth: 120,
                sortable: true
              }
            ],
            summary: [
              'unitQty',
              'unitMoney',
              'agioMoneySum',
              'refundBasUnitQty',
              'refundMoney',
              'largessBasUnitQty',
              'largessMoney'
            ]
          }
        ]
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'update':
          break
        default:
          break
      }
    }
  }
}
</script>
