<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { penUnitPriceAnalysis } from "@/api/decision/saleQueryReport/everyBillPrice"; // 笔单价分析
export default {
  name: "everyBillPrice",
  components: {
    TablePage,
  },
  data() {
    return {
      colorList: [
        "#1891FC",
        "#90CB71",
        "#FAC75A",
        "#F26362",
        "#71C1DE",
        "#389D6E",
        "#FA8550",
        "#9B61B5",
        "#E87BCA",
        "#1890FA",
      ],
      intervalList: [
        {
          value: 20,
          name: "新增会员",
          itemStyle: { color: "#FA8219" },
        },
        {
          value: 50,
          name: "全部会员",
          itemStyle: { color: "#4E95FF" },
        },
        {
          value: 30,
          name: "历史会员",
          itemStyle: { color: "#9477F9" },
        },
      ],
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        isRestTablecolumns: true,
        title: "笔单价分析",
        rowKey: "shopId",
        search: [
          {
            label: "销售时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: [
              "day",
              "yesterday",
              "week",
              "lastWeek",
              "month",
              "lastMonth",
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            type: "radio",
            label: "",
            filter: "type",
            model: 0,
            defaultValue: 0,
            seniorSearch: true,
            // seniorSearch: true,
            // change: v => {
            //   this.options.columns = this.detailedSummaryFields(
            //     this.options.tabColumnType,
            //     v
            //   )
            //   this.$refs.tablePage.getList()
            // },
            option: {
              data: [
                {
                  label: "均分区间",
                  value: 0,
                },
                {
                  label: "自选区间",
                  value: 1,
                },
              ],
            },
          },
          {
            hide: () => this.options?.search[3]?.model === 0,
            type: "equipartitionInterval",
            seniorSearch: true,
            model: {
              begin: 0,
              end: 100,
              number: 10,
            },
            defaultValue: {
              begin: 0,
              end: 100,
              number: 10,
            },
            filterFnc: (e) => e,
            // seniorSearch: true
          },
          {
            hide: () => this.options?.search[3]?.model === 1,
            type: "optionalInterval",
            seniorSearch: true,
            filter: "moneyIntervals",
            otherModel: {
              beginMoney: 0,
              endMoney: 100,
            },
            otherDefaultValue: {
              beginMoney: 0,
              endMoney: 100,
            },
            model: [
              {
                beginMoney: 0,
                endMoney: 100,
              },
            ],
            defaultValue: [
              {
                beginMoney: 0,
                endMoney: 100,
              },
            ],
            // seniorSearch: true
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        getListApi: penUnitPriceAnalysis,
        getDataKey: { list: "data" },
        getEchartsOption: async () => {
          console.log("ssss", this.options);
          await this.handleEvent("setIntervalList");
          await this.$nextTick();
          return {
            //获取会员类型分布
            tooltip: {
              trigger: "item", // 提示框触发类型
              textStyle: {
                fontSize: 16, // 你可以根据需要调整这个值
              },
            },
            // legend: {
            //   orient: 'vertical', // 图例列表的布局朝向，默认为水平
            //   right: '30px', // 图例组件离容器右侧的距离
            //   top: 'center', // 图例组件离容器上侧的距离
            //   selectedMode: true, // 关闭图例的点击事件
            //   icon: 'circle'
            // },
            series: [
              {
                // name: "Access From", // 系列名称
                type: "pie", // 图表类型为饼图
                radius: "50%",
                center: ["50%", "50%"], // 饼图的中心位置
                startAngle: 180, // 饼图的起始角度
                label: {
                  show: true, // 是否显示标签
                  fontSize: 18, // 你可以根据需要调整这个值
                  formatter(param) {
                    return `${param.name}占比{color| (${param.percent}%)}`;
                  },
                  color: "#333",
                  // 突出笔数字体和比例字体的样式
                  rich: {
                    color: {
                      fontSize: 18, // 你可以根据需要调整这个值
                      color: "#4E95FF", // 这里设置你希望突出的颜色，例如红色
                    },
                  },
                },
                data: this.intervalList,
              },
            ],
          };
        },
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "setIntervalList":
          this.intervalList = [];
          let list = this.options.list || [];
          list.forEach((range, i) => {
            this.intervalList.push({
              value: range.totalNo,
              name: `${range.moneyIntervals} 元 ${range.totalNo} 笔`,
              itemStyle: { color: this.colorList[i] },
            });
          });
          console.log("刷新3", this.options);
          break;
        default:
          break;
      }
    },
    generateRanges(minModel, maxModel, numModel) {
      const rangeSize = (maxModel - minModel) / numModel;
      const ranges = [];
      for (let i = 0; i < numModel; i++) {
        const startRange = minModel + i * rangeSize;
        const endRange = startRange + rangeSize;
        ranges.push(`${startRange} ~ ${endRange}`);
      }
      return ranges;
    },
  },
};
</script>
