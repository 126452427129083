<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import moment from "moment";
import {
  vipGrowthAnalysisList,
  vipGrowthAnalysisListSummary,
} from "@/api/decision/vipReport/vipGrowthAnalysis"; //会员增长分析
export default {
  name: "vipGrowthAnalysis",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        listNo: true,
        loading: true,
        isRestTablecolumns: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "会员增长分析",
        rowKey: "shopId",
        search: [
          {
            label: "日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "day",
            data: [
              "day",
              "yesterday",
              "week",
              "lastWeek",
              "month",
              "lastMonth",
            ],
          },
          {
            type: "filters",
            tip: "门店编码/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编码" },
              { filter: "shopNames", label: "门店名称" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        getListApi: vipGrowthAnalysisList,
        getSummaryApi: vipGrowthAnalysisListSummary,
        // defaultBody: { summaryType: 1 },
        exportOption: {
          fastExportUrl:
            "/api/system/shop/strategy/vipGrowthAnalysis/listExport",
          exportName: "会员增长分析",
        },
        columns: [
          {
            prop: "billDateStr",
            label: "日期",
            minWidth: 155,
          },
          {
            prop: "newVipQty",
            label: "新增会员数",
            minWidth: 150,
          },
          {
            prop: "orderQty",
            label: "下单人数",
            minWidth: 150,
          },
          {
            prop: "orderRate",
            label: "会员下单转化率(%)",
            minWidth: 150,
          },
        ],
        summary: ["newVipQty", "orderQty"],
        topEchartsHeight: 500,
        getTopEchartsOption: async () => {
          await this.handleEvent("setIntervalList");
          await this.$nextTick();
          let newMembers = Math.max.apply(null, this.initChartChart.newMembers); // 获取左边y轴数组的最大值
          let numberOrders = Math.max.apply(
            null,
            this.initChartChart.numberOrders
          ); // 获取右边y轴数组的最大值
          let conversionRate = Math.max.apply(
            null,
            this.initChartChart.conversionRate
          ); // 获取右边y轴数组的最大值
          let yMax = Math.max(newMembers, numberOrders); // 获取两个最大值之中的最大值
          // 如果%10有余数，这说明不是十的倍数，则修改成整十的倍数
          if (yMax % 10 != 0) yMax = yMax + (10 - (yMax % 10));
          if (yMax == 0) yMax = null;
          return {
            tooltip: {
              trigger: "axis", // 提示框触发类型
              axisPointer: {
                crossStyle: {
                  color: "#73d13d", // 十字准星的颜色
                },
              },
              formatter: (params) => {
                return `${params[0].name}<br>${params[0].marker}新增会员数:${params[0].data}(个)<br>${params[1].marker}下单人数:${params[1].data}(个)<br>${params[2].marker}会员下单转化率:${params[2].data}(%)`; // 提示框内容格式化
              },
            },
            legend: {
              data: ["新增会员数", "下单人数", "会员下单转化率(%)"], // 图例名称
              itemWidth: 30, // 设置图例项的宽度，增加线的长度
              // itemHeight: 0, // 设置图例项的高度为0，隐藏中间的点
              textStyle: {
                fontSize: 14, //文本大小
                color: "#333", // 图例文本颜色
              },
            },
            grid: {
              left: "5%", // 图表左边空白
              right: "5%", // 图表右边空白
              bottom: "5%", // 图表底部空白
              containLabel: true, // 包含坐标轴的刻度标签
            },
            xAxis: [
              {
                type: "category", // x 轴类型为类目轴
                data: this.initChartChart.dateStrArr, // x 轴数据
                axisTick: {
                  show: false, // 不显示刻度线
                },
                axisLine: {
                  lineStyle: {
                    color: "#dddddd", // x 轴线颜色
                  },
                },
                // boundaryGap: false, // 两端空白策略，false 表示留白
                axisLabel: {
                  color: "#cccccc", // x 轴文本的颜色
                  fontSize: 14, // x 轴标签字体大小
                },
              },
            ],
            yAxis: [
              {
                min: 0,
                max: yMax,
                type: "value", // y 轴类型为数值轴
                name: "", // y 轴名称
                nameGap: 25, // 设置标题与y轴的距离
                axisLine: {
                  show: false, // 不显示坐标轴轴线
                  lineStyle: {
                    color: "#999999", // y 轴线颜色
                  },
                },
                axisTick: {
                  show: false, // 不显示刻度线
                },
                splitLine: {
                  lineStyle: {
                    type: "dashed", // 分隔线样式为虚线
                    color: "#E8E8E8", // y 轴线颜色
                  },
                },
                axisLabel: {
                  formatter: "{value} 个", // 自定义刻度线显示格式，显示数值和单位
                  fontSize: 14, // y 轴标签字体大小
                },
              },
              {
                min: 0,
                max: conversionRate,
                type: "value", // y 轴类型为数值轴
                name: "", // y 轴名称
                nameGap: 25, // 设置标题与y轴的距离
                position: "right", // y 轴位置
                axisLine: {
                  show: false, // 不显示坐标轴轴线
                  lineStyle: {
                    color: "#999999", // y 轴线颜色
                  },
                },
                axisTick: {
                  show: false, // 不显示刻度线
                },
                splitLine: {
                  lineStyle: {
                    type: "dashed", // 分隔线样式为虚线
                    color: "#E8E8E8", // y 轴线颜色
                  },
                },
                axisLabel: {
                  formatter: "{value} %", // 自定义刻度线显示格式，显示数值和单位
                  fontSize: 14, // y 轴标签字体大小
                },
              },
            ],
            series: [
              {
                name: "新增会员数", // 系列名称
                type: "bar", // 系列类型为折线图
                data: this.initChartChart.newMembers, // 系列数据
                yAxisIndex: 0, // 使用的 y 轴索引
                // symbol: "circle", // 数据点图形
                // symbolSize: 8, // 数据点大小
                itemStyle: {
                  color: "#40B5FF", // 数据项样式
                },
                // lineStyle: {
                //   color: "#40B5FF", // 折线样式
                // },
                barWidth: "10%", // 设置柱状图的宽度
              },
              {
                name: "下单人数", // 系列名称
                type: "line", // 系列类型为折线图
                data: this.initChartChart.numberOrders, // 系列数据
                yAxisIndex: 0, // 使用的 y 轴索引
                symbol: "circle", // 数据点图形
                symbolSize: 8, // 数据点大小
                itemStyle: {
                  color: "#FA8219", // 数据项样式
                },
                lineStyle: {
                  color: "#FA8219", // 折线样式
                },
              },
              {
                name: "会员下单转化率(%)", // 系列名称
                type: "line", // 系列类型为折线图
                data: this.initChartChart.conversionRate, // 系列数据
                yAxisIndex: 1, // 使用的 y 轴索引
                symbol: "circle", // 数据点图形
                symbolSize: 8, // 数据点大小
                itemStyle: {
                  color: "#50d873", // 数据项样式
                },
                lineStyle: {
                  color: "#50d873", // 折线样式
                },
              },
            ],
          };
        },
      },
      initChartChart: {}, //图表
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "setIntervalList":
          // 首先，按照 billDateStr 对 this.options.list 进行排序
          this.options.list.sort((a, b) => {
            return new Date(a.billDateStr) - new Date(b.billDateStr);
          });
          this.initChartChart = {
            dateStrArr: [],
            newMembers: [],
            numberOrders: [],
            conversionRate: [],
          };
          this.options.list.forEach((range, i) => {
            this.initChartChart.dateStrArr.push(range.billDateStr);
            this.initChartChart.newMembers.push(range.newVipQty);
            this.initChartChart.numberOrders.push(range.orderQty);
            this.initChartChart.conversionRate.push(range.orderRate);
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss">
</style>
